import React from 'react';
import './auth.scss';
import { Button, Col, Row } from 'reactstrap';
import { graphql, useStaticQuery } from 'gatsby';

interface Props {
    authState?: string;
}

const FederatedSignIn = ({ authState }: Props) => {

    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          client
          cognitoRegion
          cognitoClientId
          samlRedirectUrl
        }
      }
    }
  `);

    const maiaSupportLogin = () => {
        window.location.assign(
            'https://maia-fast-' + data.site.siteMetadata.client + '.auth.'
            + data.site.siteMetadata.cognitoRegion + '.amazoncognito.com/oauth2/authorize?response_type=token&identity_provider=Okta&client_id='
            + data.site.siteMetadata.cognitoClientId + '&scope=email+openid+profile+aws.cognito.signin.user.admin&redirect_uri=' + data.site.siteMetadata.samlRedirectUrl + "/app/federatedLogin"
        );
    };

    return (<div>
        {authState == 'signIn' &&
        <div className={'federatedLogin'}>
            <Row form className={'align-items-center'}>
                <Col xs={12} md={{ size: 7 }}>MAIA Technology Support:</Col>
                <Col xs={12} md={{ size: 4, offset: 1 }}>
                    <Button block onClick={maiaSupportLogin} className={'federatedLoginButton'}>MAIA Login</Button>
                </Col>
            </Row>

        </div>
        }
    </div>);
};

export default FederatedSignIn;