import DashboardPage from './DashboardPage';
import React, { useState } from 'react';
import { Authenticator, Greetings, SignUp } from 'aws-amplify-react';
import { onAuthStateChange } from '../services/auth';
import { navigate } from 'gatsby-link';
import FederatedSignIn from './auth/FederatedSignIn';

const authTheme = {
    button: {
        backgroundColor: 'rgb(12, 158, 145)',
    },
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Props {
}

export const Login = ({}: Props) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);


    const onStateChange = (authState: string) => {
        onAuthStateChange(authState)
            .then((user) => {
                if (user) {
                    setIsLoggedIn(true);
                } else {
                    setIsLoggedIn(false);
                }
            })
    };

    if (isLoggedIn) {
        navigate('/app/downloads');
        return null;
    } else {
        return (
            <DashboardPage seoTitle={'Maia'} sideMenuItems={[]}>
                <h1>Welcome to Maia</h1>
                <p>Please log in.</p>
                <Authenticator theme={authTheme} onStateChange={onStateChange} hide={[Greetings, SignUp]}>
                    <FederatedSignIn/>
                </Authenticator>
            </DashboardPage>
        );
    }
};

